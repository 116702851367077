










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class VisualProfileSquare extends Vue {
  @Prop({ required: true }) squareKey!: string;
  @Prop({ required: true }) values!: string[];
}
