








































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { namespace } from "vuex-class";
const baseModule = namespace("base");

import { ApiRequest } from "@/store/types";
import { BaseActionTypes, BaseGetterTypes } from "@/store/base/types";
import { QuestionRegistration } from "@/models";

import { parsePhoneNumberFromString } from "libphonenumber-js";

import { mixins } from "vue-class-component";
import ContentComponent from "@/components/ContentComponent";

@Component({
  components: {},
})
export default class QuestionRegistrationView extends mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "question registration";
  }

  @baseModule.Action(BaseActionTypes.RegisterQuestion)
  RegisterQuestion!: (request: QuestionRegistration) => void;

  @baseModule.Getter(BaseGetterTypes.QuestionRegistration)
  questionRegistration!: ApiRequest<QuestionRegistration>;

  email: string = "";
  phoneNumber: string = "";
  givenName: string = "";
  familyName: string = "";
  question: string = "";

  get validEmail() {
    if (!this.email) {
      return true;
    }
    return this.validateEmail(this.email);
  }

  get validPhonenumber() {
    if (this.phoneNumber === "") {
      return true;
    }
    return this.validatePhonenumber(this.phoneNumber);
  }

  // from https://stackoverflow.com/a/9204568
  validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
  }

  validatePhonenumber(number) {
    const dutchPhonenumber = parsePhoneNumberFromString(number, "NL");
    if (dutchPhonenumber && dutchPhonenumber.isValid()) {
      return true;
    }

    const intlPhonenumber = parsePhoneNumberFromString(number);
    if (intlPhonenumber && intlPhonenumber.isValid()) {
      return true;
    }

    return false;
  }

  get validated(): boolean {
    return (
      this.phoneNumber !== "" &&
      this.validatePhonenumber(this.phoneNumber) &&
      this.email !== "" &&
      this.validateEmail(this.email) &&
      this.familyName !== "" &&
      this.givenName !== ""
    );
  }

  onClick() {
    const question: QuestionRegistration = {
      givenName: this.givenName,
      familyName: this.familyName,
      phoneNumber: this.phoneNumber,
      email: this.email,
      question: this.question,
    };
    this.RegisterQuestion(question);
  }
}
