



















import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import ContentComponent from "@/components/ContentComponent";
import { Meta } from "@sophosoft/vue-meta-decorator";

import { namespace } from "vuex-class";
const mentorModule = namespace("mentors");
const usergroupModule = namespace("usergroups");
import { ApiRequest } from "@/store/types";

import {
  Mentor,
  ScheduleAppointment,
  Content,
  Usergroup,
  UsergroupType,
} from "@/models";
import MentorDetails from "@/components/MentorDetails.vue";
import { MentorGetterTypes, MentorActionTypes } from "../store/mentors/types";

import {
  UsergroupActionTypes,
  UsergroupGetterTypes,
} from "../store/usergroups/types";

@Component({
  components: {
    MentorDetails,
  },
})
export default class MentorDetailPage extends Mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "mentor detail";
  }

  // Mentor
  @mentorModule.Action(MentorActionTypes.LoadMentor)
  LoadMentor!: ({ id: string, forEvent: boolean }) => void;

  @mentorModule.Action(MentorActionTypes.ClearMentor)
  ClearMentor!: () => void;

  @mentorModule.Action(MentorActionTypes.ScheduleAppointment)
  ScheduleAppointment!: (request: ScheduleAppointment) => void;

  @mentorModule.Action(MentorActionTypes.ClearAppointment)
  ClearAppointment!: () => void;

  @mentorModule.Getter(MentorGetterTypes.Mentor)
  mentor!: ApiRequest<Mentor>;

  @mentorModule.Getter(MentorGetterTypes.Appointment)
  scheduleAppointment!: ApiRequest<ScheduleAppointment>;

  @mentorModule.Action(MentorActionTypes.LoadMentors)
  LoadMentors!: () => void;

  // Usergroup
  @usergroupModule.Action(UsergroupActionTypes.LoadUsergroup)
  LoadUsergroup!: ({ usergroupType: UsergroupType, slug: string }) => void;

  @usergroupModule.Getter(UsergroupGetterTypes.Usergroup)
  usergroup!: ApiRequest<Usergroup>;

  @usergroupModule.Action(UsergroupActionTypes.ClearUsergroup)
  ClearUsergroup!: () => void;

  appointmentSubmitted(e: ScheduleAppointment) {
    this.ScheduleAppointment(e);
  }

  @Meta
  getMetaInfo() {
    if (!this.mentor || !this.mentor.value) {
      return { title: "Mentorpage" };
    } else {
      return {
        title: `${this.mentor.value.givenName} ${this.mentor.value.familyName}`,
      };
    }
  }

  get usergroupSlug() {
    return this.$route.params.usergroupSlug;
  }

  get id() {
    return this.$route.params.id;
  }

  usergroupType: UsergroupType | null = null;

  // Server-side only
  // This will be called by the server renderer automatically
  async serverPrefetch(): Promise<any> {
    // return the Promise from the action
    // so that the component waits before rendering
    const loadMentorPromise = this.LoadMentor({
      id: this.id,
      forEvent: !!this.usergroupSlug,
    });

    if (this.usergroupSlug) {
      const loadUsergroupPromise = this.LoadUsergroup({
        usergroupType: this.usergroupType,
        slug: this.usergroupSlug,
      });
      return Promise.all([loadMentorPromise, loadUsergroupPromise]);
    }
    return loadMentorPromise;
  }

  mounted() {
    this.usergroupType =
      UsergroupType[
        this.$route.meta.usergroupType as keyof typeof UsergroupType
      ];
    if (!this.mentor.value) {
      this.LoadMentor({ id: this.id, forEvent: !!this.usergroupSlug });
    }
    if (!this.usergroup.value && this.usergroupSlug) {
      this.LoadUsergroup({
        usergroupType: this.usergroupType,
        slug: this.usergroupSlug,
      });
    }
    this.ClearAppointment();
  }

  beforeDestroy() {
    this.ClearMentor();
    this.ClearUsergroup();
  }
}
