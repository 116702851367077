






























































































import { Component, Vue, Prop } from "vue-property-decorator";

import { Mentor, ScheduleAppointment, Content } from "../models";

import VisualProfileSquare from "@/components/VisualProfileSquare.vue";
import MentorDetailTabbedContent from "@/components/MentorDetailTabbedContent.vue";
import ScheduleAnAppointment from "@/components/ScheduleAnAppointment.vue";
import { ApiResult } from "../store/types";

import Tarief from "@/assets/tarief.svg";
import Reviews from "@/assets/review.svg";
import Sessions from "@/assets/sessies-alt.svg";
import Language from "@/assets/taal.svg";
import Phone from "@/assets/telefoon-12.svg";
import Meerzien from "@/assets/meerzien.svg";
import { Usergroup } from "../models";

@Component({
  components: {
    Tarief,
    Reviews,
    Language,
    Phone,
    Sessions,
    Meerzien,
    VisualProfileSquare,
    MentorDetailTabbedContent,
    ScheduleAnAppointment,
  },
})
export default class MentorDetails extends Vue {
  @Prop() mentor!: Mentor;
  @Prop() usergroup!: Usergroup;
  @Prop() scheduleAppointment!: ApiResult<ScheduleAppointment>;
  @Prop() contents!: Content[];

  showModal: boolean = false;
  tabbedContent: Object;

  appointmentSubmitted(e: any) {
    this.$emit("appointmentSubmitted", e);
  }

  getContent(name) {
    const content = this.contents.find(c => c.name == name);
    return content ? content.body : name;
  }
  /**
   *
   */
  constructor() {
    super();
    this.tabbedContent = this.mentor.mentorCompetences;
  }

  toggle() {
    this.showModal = !this.showModal;
    setTimeout(() => {
      if (this.showModal) {
        let baf = document.getElementById("bookAppointmentForm");
        if (baf) {
          baf.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 100);
  }

  get headerImageStyle() {
    return `background-image: url('${
      this.usergroup ? this.usergroup.imageSrc : this.mentor.headerImageSrc
    }');`;
  }
}
