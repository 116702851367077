



















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { namespace } from "vuex-class";
const mentorModule = namespace("mentors");

import { ApiRequest } from "@/store/types";
import { MentorActionTypes, MentorGetterTypes } from "@/store/mentors/types";
import { MentorRegistration } from "@/models";

import { parsePhoneNumberFromString } from "libphonenumber-js";

import { mixins } from "vue-class-component";
import ContentComponent from "@/components/ContentComponent";
import MentorRegistrationComponent from "@/components/MentorRegistration.vue";
@Component({
  components: { MentorRegistrationComponent },
})
export default class MentorRegistrationView extends mixins(ContentComponent) {
  @mentorModule.Action(MentorActionTypes.RegisterMentor)
  RegisterMentor!: (request: MentorRegistration) => void;

  @mentorModule.Getter(MentorGetterTypes.MentorRegistration)
  mentorRegistration!: ApiRequest<MentorRegistration>;

  constructor() {
    super();
    this.componentName = "mentor registration";
  }
}
