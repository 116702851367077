var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.contentLoaded())?_c('div',{staticClass:"question-registration"},[_c('div',{staticClass:"question-registration-head"},[_c('h1',[_vm._v(_vm._s(_vm.getContentBody("question-registration-title")))]),_c('p',[_vm._v(_vm._s(_vm.getContentBody("question-registration-intro")))]),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"question-registration-head-intro"},[_vm._v("\n      "+_vm._s(_vm.getContentBody("question-registration-body"))+"\n    ")])]),_c('div',{staticClass:"question-registration-body"},[_c('div',{staticClass:"question-registration-body-form"},[_c('div',{staticClass:"question-registration-body-form-text"},[_c('h1',[_vm._v(_vm._s(_vm.getContentBody("question-registration-form-title")))]),_c('p',[_vm._v(_vm._s(_vm.getContentBody("question-registration-form-body")))])]),(_vm.questionRegistration.error)?_c('div',{staticClass:"question-registration-body-error"},[_c('h2',[_vm._v("\n          "+_vm._s(_vm.getContentBody("question-registration-form-error-title"))+"\n        ")]),_c('p',[_vm._v(_vm._s(_vm.getContentBody("question-registration-form-error-body")))])]):_vm._e(),(_vm.questionRegistration.value)?_c('div',{staticClass:"question-registration-body-confirm"},[_c('h2',[_vm._v("\n          "+_vm._s(_vm.getContentBody("question-registration-form-confirm-title"))+"\n        ")]),_c('p',[_vm._v(_vm._s(_vm.getContentBody("question-registration-form-confirm-body")))])]):_vm._e(),(_vm.questionRegistration.empty)?_c('div',{staticClass:"question-registration-body-form-form"},[_c('div',[_c('div',{staticClass:"section"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.question),expression:"question"}],staticClass:"question",attrs:{"placeholder":_vm.getContentBody(
                  'question-registration-form-question-field-placeholder'
                ),"id":"question","type":"text","name":"question"},domProps:{"value":(_vm.question)},on:{"input":function($event){if($event.target.composing){ return; }_vm.question=$event.target.value}}})]),_c('div',{staticClass:"section"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.givenName),expression:"givenName"}],attrs:{"id":"givenName","placeholder":_vm.getContentBody(
                  'question-registration-form-given-name-field-placeholder'
                ),"type":"text","name":"givenName"},domProps:{"value":(_vm.givenName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.givenName=$event.target.value}}})]),_c('div',{staticClass:"section"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.familyName),expression:"familyName"}],attrs:{"id":"familyName","placeholder":_vm.getContentBody(
                  'question-registration-form-family-name-field-placeholder'
                ),"type":"text","name":"familyName"},domProps:{"value":(_vm.familyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.familyName=$event.target.value}}})]),_c('div',{staticClass:"section"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],attrs:{"id":"phoneNumber","placeholder":_vm.getContentBody(
                  'question-registration-form-phone-number-field-placeholder'
                ),"type":"text","name":"phoneNumber"},domProps:{"value":(_vm.phoneNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phoneNumber=$event.target.value}}}),_c('p',{staticClass:"error",class:{ hidden: _vm.validPhonenumber }},[_c('small',[_vm._v("\n                "+_vm._s(_vm.getContentBody(
                    "question-registration-form-phone-number-field-error"
                  ))+"\n              ")])])]),_c('div',{staticClass:"section"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"id":"email","placeholder":_vm.getContentBody(
                  'question-registration-form-email-field-placeholder'
                ),"type":"text","name":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('p',{staticClass:"error",class:{ hidden: _vm.validEmail }},[_c('small',[_vm._v("\n                "+_vm._s(_vm.getContentBody(
                    "question-registration-form-email-field-error"
                  ))+"\n              ")])])]),_c('div',{staticClass:"button-row"},[_c('button',{attrs:{"disabled":!_vm.validated},on:{"click":_vm.onClick}},[_vm._v("\n              "+_vm._s(_vm.getContentBody("question-registration-form-button-title"))+"\n            ")])])])]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }