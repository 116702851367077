






































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  Mentor,
  ScheduleAppointment,
  Content,
  apiTimeslotToTimeslot,
  Timeslot,
} from "@/models";
import { DateTime, Settings } from "luxon";
import { ApiResult } from "@/store/types";
import content from "*.svg";

import { Multiselect } from "vue-multiselect";
import { parsePhoneNumberFromString } from "libphonenumber-js";
Settings.defaultLocale = "nl";

@Component({ components: { Multiselect } })
export default class ScheduleAnAppointment extends Vue {
  @Prop() mentor!: Mentor;
  @Prop() scheduleAppointment!: ApiResult<ScheduleAppointment>;
  @Prop() contents!: Content[];

  email: string = "";
  phoneNumber: string = "";
  name: string = "";
  toolId: string = "";
  defaultMethod = this.mentor.contactMethods[0];
  tool = {
    name: this.defaultMethod,
    label: this.getBlock(
      `mentor-detail-schedule-contact-${this.defaultMethod}`,
    ),
  };
  question: string = "";
  toolOptions = [];
  availabilityOptions = [];
  termsAndConditions = false;
  subscribeNewsletter = false;
  showMissing = false;

  get noFit() {
    return this.getBlock("mentor-detail-schedule-no-timeslot-fits-option");
  }

  get nonAvailable() {
    return this.getBlock("mentor-detail-schedule-no-timeslot-available-option");
  }

  mounted() {
    this.toolOptions = this.mentor.contactMethods.map(method => ({
      name: method,
      label: this.getBlock(`mentor-detail-schedule-contact-${method}`),
    }));

    this.availabilityOptions =
      this.mentor.availability && this.mentor.availability.length > 0
        ? [
            ...this.mentor.availability
              .map(apiTimeslotToTimeslot)
              .map(slot => ({
                slot,
                label: this.formatSlot(slot),
              })),
            {
              slot: { reason: this.noFit },
              label: this.noFit,
            },
          ]
        : [
            {
              slot: { reason: this.nonAvailable },
              label: this.nonAvailable,
            },
          ];
  }

  appointmentTimeslot: { slot: Timeslot; label: string } | null = this.mentor
    .availability.length
    ? {
        slot: apiTimeslotToTimeslot(this.mentor.availability[0]),
        label: this.formatSlot(
          apiTimeslotToTimeslot(this.mentor.availability[0]),
        ),
      }
    : null;

  formatSlot(slot: Timeslot): string {
    const { startTime, endTime } = slot;
    return `${startTime.toFormat("cccc d LLL HH:mm")}-${endTime.toFormat(
      "HH:mm",
    )}`;
  }

  getBlock(name): string {
    const content = this.contents.find(c => c.name === name);
    return content ? content.body : `missing content: ${name}`;
  }

  // validators

  get validQuestion() {
    return this.question && this.question.length > 0;
  }

  // Some very basic validation: we want to check for 'givenName familyName'
  get validName() {
    return this.name && this.name.length > 3 && this.name.indexOf(" ") > 0;
  }

  // from https://stackoverflow.com/a/46181 as suggested by https://vuejs.org/v2/cookbook/form-validation.html#Using-Custom-Validation
  get validEmail() {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.email).toLowerCase());
  }

  get validPhonenumber() {
    if (!this.phoneNumber && this.tool.name !== "telephone") {
      return true;
    }
    const dutchPhonenumber = parsePhoneNumberFromString(this.phoneNumber, "NL");
    if (dutchPhonenumber && dutchPhonenumber.isValid()) {
      return true;
    }

    const intlPhonenumber = parsePhoneNumberFromString(this.phoneNumber);
    if (intlPhonenumber && intlPhonenumber.isValid()) {
      return true;
    }

    return false;
  }

  get validAppointmentSlot() {
    return this.appointmentTimeslot ? true : false;
  }

  get needToolId() {
    return this.tool.name !== "telephone";
  }

  get needPhonenumber() {
    return this.tool.name === "telephone";
  }

  get validToolId() {
    if (!this.needToolId) {
      return true;
    }
    return this.toolId && this.toolId.length > 1;
  }

  get validTerms() {
    return this.termsAndConditions ? true : false;
  }

  // end validators

  get validForm(): boolean {
    return (
      this.validQuestion &&
      this.validName &&
      this.validEmail &&
      this.validPhonenumber &&
      this.validAppointmentSlot &&
      this.validToolId &&
      this.validTerms
    );
  }

  onClick() {
    if (!this.validForm) {
      this.showMissing = true;
      return;
    }

    if (
      this.appointmentTimeslot.label == this.noFit ||
      this.appointmentTimeslot.label == this.nonAvailable
    ) {
      const appointment: ScheduleAppointment = {
        mentorId: this.mentor.id,
        name: this.name,
        tool: this.tool ? this.tool.name : "",
        toolDescription: this.tool
          ? this.getBlock(`mentor-detail-schedule-contact-${this.tool.name}`)
          : "",
        toolId: this.toolId || this.phoneNumber,
        phoneNumber: this.phoneNumber,
        email: this.email,
        question: this.question,
        reason: this.appointmentTimeslot.label,
        subscribeNewsletter: this.subscribeNewsletter,
      };

      this.$emit("appointmentSubmitted", appointment);
    } else {
      const appointment: ScheduleAppointment = {
        mentorId: this.mentor.id,
        name: this.name,
        tool: this.tool ? this.tool.name : "",
        toolDescription: this.tool
          ? this.getBlock(`mentor-detail-schedule-contact-${this.tool.name}`)
          : "",
        toolId: this.toolId || this.phoneNumber,
        phoneNumber: this.phoneNumber,
        email: this.email,
        question: this.question,
        timeslot: this.appointmentTimeslot.slot,
        subscribeNewsletter: this.subscribeNewsletter,
      };

      this.$emit("appointmentSubmitted", appointment);
    }
  }
}
