

































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VueMarkdown from "vue-markdown";
import { MentorCompetence, Content } from "../models";

@Component({ components: { VueMarkdown } })
export default class MentorDetailTabbedContent extends Vue {
  currentTab: number = 0;
  @Prop() private title!: string;
  @Prop() private compentence!: MentorCompetence;
  @Prop() private tabbedContent!: Array<MentorCompetence>;
  @Prop() contents!: Content[];

  show(tabId: number) {
    this.currentTab = tabId;
  }

  getContent(name) {
    const content = this.contents.find(c => c.name == name);
    return content ? content.body : name;
  }
}
