








































import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import ContentComponent from "@/components/ContentComponent";

import { namespace } from "vuex-class";
const mentorModule = namespace("mentors");

import { ApiRequest } from "@/store/types";

import { Mentor, FilterOptions, FilterObject } from "@/models";
import MentorGrid from "@/components/MentorGrid.vue";
import MentorFilter from "@/components/MentorFilter.vue";

import { MentorGetterTypes, MentorActionTypes } from "../store/mentors/types";

@Component({
  components: {
    MentorGrid,
    MentorFilter,
  },
})
export default class MentorOverview extends Mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "mentor overview";
  }

  // Mentors
  @mentorModule.Action(MentorActionTypes.ClearMentor)
  ClearMentor!: () => void;

  @mentorModule.Action(MentorActionTypes.LoadMentors)
  LoadMentors!: () => void;

  @mentorModule.Getter(MentorGetterTypes.Mentors)
  mentors!: ApiRequest<Mentor[]>;

  @mentorModule.Action(MentorActionTypes.LoadFilterOptions)
  LoadFilterOptions!: () => void;

  @mentorModule.Action(MentorActionTypes.LoadFilteredMentors)
  LoadFilteredMentors!: (filter: FilterObject) => void;

  @mentorModule.Getter(MentorGetterTypes.FilterOptions)
  filterOptions!: ApiRequest<FilterOptions>;

  get mentorName() {
    return this.$route.params.mentorName;
  }

  filterChanged(filter: FilterObject) {
    this.LoadFilteredMentors(filter);
  }

  // Server-side only
  // This will be called by the server renderer automatically
  async serverPrefetch(): Promise<any> {
    // return the Promise from the action
    // so that the component waits before rendering
    return Promise.all([this.LoadFilterOptions(), this.LoadMentors()]);
  }

  mounted() {
    this.ClearMentor();
    if (!this.filterOptions.value) {
      this.LoadFilterOptions();
    }
    if (!this.mentors.value) {
      this.LoadMentors();
    }
  }
}
